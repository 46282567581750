// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/opt/buildhome/repo/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/opt/buildhome/repo/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'/opt/buildhome/repo/src/pages/user/login'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "register",
                "path": "/user/register",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register' */'/opt/buildhome/repo/src/pages/user/register'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/buildhome/repo/src/pages/404'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          }
        ]
      },
      {
        "path": "/preview/:encodedParams",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__preview' */'/opt/buildhome/repo/src/pages/preview'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/oazalo/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__oazalo' */'/opt/buildhome/repo/src/pages/oazalo'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/index.html",
        "access": "isLandingPage",
        "name": "UCALL - Tổng đài Callbot AI Auto Call",
        "description": "Giải pháp auto call ứng dụng công nghệ AI (trí tuệ nhân tạo) giúp tối ưu chi phí, thời gian cho doanh nghiệp đồng thời đạt hiệu quả cao. Phần mềm auto call của UCALL sẽ giúp doanh nghiệp thuận tiện hơn trong việc tìm kiếm khách hàng, telesales, telemarketing và chăm sóc khách hàng",
        "keywords": "giải pháp auto call, gọi tự động, voicebot, dịch vụ auto call, phần mềm auto call, tự động gọi, phần mềm auto gọi điện",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__index' */'/opt/buildhome/repo/src/pages/new/index'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/",
        "access": "isLandingPage",
        "name": "UCALL - Tổng đài Callbot AI Auto Call",
        "description": "Giải pháp auto call ứng dụng công nghệ AI (trí tuệ nhân tạo) giúp tối ưu chi phí, thời gian cho doanh nghiệp đồng thời đạt hiệu quả cao. Phần mềm auto call của UCALL sẽ giúp doanh nghiệp thuận tiện hơn trong việc tìm kiếm khách hàng, telesales, telemarketing và chăm sóc khách hàng",
        "keywords": "giải pháp auto call, gọi tự động, voicebot, dịch vụ auto call, phần mềm auto call, tự động gọi, phần mềm auto gọi điện",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__index' */'/opt/buildhome/repo/src/pages/new/index'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/solutions",
        "name": "UCALL - Giải pháp callbot AI auto call",
        "access": "isLandingPage",
        "keywords": "dịch vụ auto call, dịch vụ gọi tự động, dich vu auto call, tổng đài auto call (tỉ lệ tăng trưởng cao)",
        "description": "Tổng đài auto call do UCALL cung cấp sẽ giúp doanh nghiệp giảm thiểu chi phí và thời gian; đồng thời dịch vụ auto call cũng sẽ giúp doanh nghiệp tìm kiếm data khách hàng, telesales, telemarketing và chăm sóc khách hàng tiết kiệm thời gian, chi phí nhưng vẫn đảm bảo hiệu quả.",
        "layout": false,
        "routes": [
          {
            "path": "/solutions/telemarketing-telesale-tu-dong",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Telemarketing, Telesale tự động",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__telemarketing-telesale-tu-dong' */'/opt/buildhome/repo/src/pages/new/solutions/telemarketing-telesale-tu-dong'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/telemarketing-telesale/ban-le",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Bán lẻ",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__telemarketing-telesale__ban-le' */'/opt/buildhome/repo/src/pages/new/solutions/telemarketing-telesale/ban-le'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/telemarketing-telesale/bao-hiem",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Bảo hiểm",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__telemarketing-telesale__bao-hiem' */'/opt/buildhome/repo/src/pages/new/solutions/telemarketing-telesale/bao-hiem'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/telemarketing-telesale/bat-dong-san",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Bảo hiểm",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__telemarketing-telesale__bat-dong-san' */'/opt/buildhome/repo/src/pages/new/solutions/telemarketing-telesale/bat-dong-san'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/telemarketing-telesale/giao-duc",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Giáo dục",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__telemarketing-telesale__giao-duc' */'/opt/buildhome/repo/src/pages/new/solutions/telemarketing-telesale/giao-duc'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/telemarketing-telesale/spa",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Spa",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__telemarketing-telesale__spa' */'/opt/buildhome/repo/src/pages/new/solutions/telemarketing-telesale/spa'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/telemarketing-telesale/tai-chinh",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Tài chính",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__telemarketing-telesale__tai-chinh' */'/opt/buildhome/repo/src/pages/new/solutions/telemarketing-telesale/tai-chinh'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/cham-soc-khach-hang-tu-dong",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Chăm sóc khách hàng tự động",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__cham-soc-khach-hang-tu-dong' */'/opt/buildhome/repo/src/pages/new/solutions/cham-soc-khach-hang-tu-dong'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/cham-soc-khach-hang/chot-don-tu-dong",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Chốt đơn tự động",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__cham-soc-khach-hang__chot-don-tu-dong' */'/opt/buildhome/repo/src/pages/new/solutions/cham-soc-khach-hang/chot-don-tu-dong'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/cham-soc-khach-hang/khao-sat-thi-truong",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Khảo sát thị trường",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__cham-soc-khach-hang__khao-sat-thi-truong' */'/opt/buildhome/repo/src/pages/new/solutions/cham-soc-khach-hang/khao-sat-thi-truong'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/cham-soc-khach-hang/lay-feedback-khach-hang",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Lấy feedback khách hàng",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__cham-soc-khach-hang__lay-feedback-khach-hang' */'/opt/buildhome/repo/src/pages/new/solutions/cham-soc-khach-hang/lay-feedback-khach-hang'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/cham-soc-khach-hang/thong-bao-chuong-trinh",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Thông báo chương trình",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__cham-soc-khach-hang__thong-bao-chuong-trinh' */'/opt/buildhome/repo/src/pages/new/solutions/cham-soc-khach-hang/thong-bao-chuong-trinh'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/solutions/cham-soc-khach-hang/nhac-lich-tu-dong",
            "access": "isLandingPage",
            "name": "UCALL - Giải pháp Callbot Nhắc lịch tự động",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__solutions__cham-soc-khach-hang__nhac-lich-tu-dong' */'/opt/buildhome/repo/src/pages/new/solutions/cham-soc-khach-hang/nhac-lich-tu-dong'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          }
        ]
      },
      {
        "path": "/products",
        "name": "UCALL - Giải pháp callbot AI auto call",
        "access": "isLandingPage",
        "keywords": "dịch vụ auto call, dịch vụ gọi tự động, dich vu auto call, tổng đài auto call (tỉ lệ tăng trưởng cao)",
        "description": "Tổng đài auto call do UCALL cung cấp sẽ giúp doanh nghiệp giảm thiểu chi phí và thời gian; đồng thời dịch vụ auto call cũng sẽ giúp doanh nghiệp tìm kiếm data khách hàng, telesales, telemarketing và chăm sóc khách hàng tiết kiệm thời gian, chi phí nhưng vẫn đảm bảo hiệu quả.",
        "layout": false,
        "routes": [
          {
            "path": "/products/callbot-ai-auto-call",
            "name": "UCALL - Giải pháp Callbot AI gọi tự động",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__products__callbot-ai-auto-call' */'/opt/buildhome/repo/src/pages/new/products/callbot-ai-auto-call'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/products/tong-dai-telesale",
            "name": "UCALL - Tổng đài telesale",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__products__tong-dai-telesale' */'/opt/buildhome/repo/src/pages/new/products/tong-dai-telesale'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          },
          {
            "path": "/products/ulead",
            "name": "UCALL - Giải pháp Tìm kiếm khách hàng Ulead",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__products__ulead' */'/opt/buildhome/repo/src/pages/new/products/ulead'), loading: LoadingComponent}),
            "layout": false,
            "exact": true
          }
        ]
      },
      {
        "path": "/pricing",
        "name": "UCALL - Giá và gói bán",
        "description": "UCALL gửi đến khách hàng và doanh nghiệp bảng giá và chi phí sử dụng giải pháp callbot AI auto call của chúng tôi nhằm giúp khách hàng dễ dàng hơn trong việc cân nhắc và lựa chọn gói dịch vụ auto call phù hợp cho doanh nghiệp của mình.",
        "access": "isLandingPage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__pricing' */'/opt/buildhome/repo/src/pages/new/pricing'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/help",
        "name": "UCALL - Hướng dẫn sử dụng",
        "description": "Hướng dẫn sử dụng Giải pháp AI auto call của UCALL giúp bạn dễ dàng tạo ra những chiến dịch gọi tự động và sử dụng phần mềm auto call được dễ dàng và hiệu quả hơn.",
        "access": "isLandingPage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__help' */'/opt/buildhome/repo/src/pages/help'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/contact",
        "name": "UCALL - Liên hệ'",
        "access": "isLandingPage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__contact' */'/opt/buildhome/repo/src/pages/contact'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/order",
        "name": "UCALL - Đặt Hàng'",
        "access": "isLandingPage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__order' */'/opt/buildhome/repo/src/pages/new/order'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/payment",
        "name": "UCALL - Thanh Toán'",
        "access": "isLandingPage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__new__payment' */'/opt/buildhome/repo/src/pages/new/payment'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/p",
        "layout": false,
        "access": "isLandingPage",
        "routes": [
          {
            "path": "/p/data",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__p__data' */'/opt/buildhome/repo/src/pages/p/data'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/p/terms",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__p__terms' */'/opt/buildhome/repo/src/pages/p/terms'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/p/delivery",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__p__delivery' */'/opt/buildhome/repo/src/pages/p/delivery'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/p/payment",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__p__payment' */'/opt/buildhome/repo/src/pages/p/payment'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/confirmation",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__confirmation' */'/opt/buildhome/repo/src/pages/confirmation'), loading: LoadingComponent}),
        "isExact": true,
        "exact": true
      },
      {
        "path": "/onboard",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__onboard' */'/opt/buildhome/repo/src/pages/onboard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/company/:companyID",
        "menu": {
          "name": "uCall",
          "icon": "",
          "flatMenu": true,
          "hideInMenu": false,
          "hideChildrenInMenu": false
        },
        "routes": [
          {
            "path": "/company/:companyID/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__welcome' */'/opt/buildhome/repo/src/pages/welcome'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/company/:companyID/account",
            "routes": [
              {
                "name": "Đổi Mật Khẩu",
                "path": "/company/:companyID/account/change-password",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__change-password' */'/opt/buildhome/repo/src/pages/account/change-password'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/company/:companyID/partner",
            "name": "partner",
            "icon": "star",
            "access": "isPartner",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partner' */'/opt/buildhome/repo/src/pages/partner'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/company/:companyID/customers",
            "name": "customers",
            "icon": "team",
            "access": "canAdmin",
            "routes": [
              {
                "path": "/company/:companyID/customers/groups/",
                "name": "customer_groups",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customers__groups' */'/opt/buildhome/repo/src/pages/customers/groups'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/customers/",
                "name": "customers_list",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customers' */'/opt/buildhome/repo/src/pages/customers'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/customers/imports/",
                "name": "uLeads",
                "access": "isDisplayUleads",
                "icon": "import",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customers__import' */'/opt/buildhome/repo/src/pages/customers/import'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/buildhome/repo/src/pages/404'), loading: LoadingComponent}),
                "layout": false,
                "exact": true
              }
            ]
          },
          {
            "path": "/company/:companyID/scripts",
            "name": "scripts",
            "icon": "file",
            "access": "canAdmin",
            "routes": [
              {
                "path": "/company/:companyID/scripts/",
                "name": "scripts",
                "icon": "file",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__scripts' */'/opt/buildhome/repo/src/pages/scripts'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/scripts/scripts/templates",
                "name": "templates",
                "icon": "file",
                "access": "isDev",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__scripts__templates' */'/opt/buildhome/repo/src/pages/scripts/templates'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/scripts/:CALL_BOT_SCENARIO_ID/detail",
                "icon": "file",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__scripts__CALL_BOT_SCENARIO_ID__detail' */'/opt/buildhome/repo/src/pages/scripts/[CALL_BOT_SCENARIO_ID]/detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/buildhome/repo/src/pages/404'), loading: LoadingComponent}),
                "layout": false,
                "exact": true
              }
            ]
          },
          {
            "path": "/company/:companyID/campaigns",
            "name": "campaigns",
            "icon": "notification",
            "access": "canAdmin",
            "routes": [
              {
                "path": "/company/:companyID/campaigns/calls",
                "name": "call_campaigns",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__calls' */'/opt/buildhome/repo/src/pages/campaigns/calls'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/agents",
                "name": "agents",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__agents' */'/opt/buildhome/repo/src/pages/campaigns/agents'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/dials",
                "name": "dials",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__dials' */'/opt/buildhome/repo/src/pages/campaigns/dials'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/messages",
                "name": "messages",
                "access": "isDev",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__messages' */'/opt/buildhome/repo/src/pages/campaigns/messages'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/dials/:DIAL_CAMPAIGN_ID/detail",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__dials__DIAL_CAMPAIGN_ID__detail' */'/opt/buildhome/repo/src/pages/campaigns/dials/[DIAL_CAMPAIGN_ID]/detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/dials/:DIAL_CAMPAIGN_ID/start",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__dials__DIAL_CAMPAIGN_ID__start' */'/opt/buildhome/repo/src/pages/campaigns/dials/[DIAL_CAMPAIGN_ID]/start'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/dials/:DIAL_CAMPAIGN_ID/report",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__dials__DIAL_CAMPAIGN_ID__report' */'/opt/buildhome/repo/src/pages/campaigns/dials/[DIAL_CAMPAIGN_ID]/report'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/agents/:AGENT_CAMPAIGN_ID/detail",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__agents__AGENT_CAMPAIGN_ID__detail' */'/opt/buildhome/repo/src/pages/campaigns/agents/[AGENT_CAMPAIGN_ID]/detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/agents/:AGENT_CAMPAIGN_ID/report-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__agents__AGENT_CAMPAIGN_ID__report-detail' */'/opt/buildhome/repo/src/pages/campaigns/agents/[AGENT_CAMPAIGN_ID]/report-detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/agents/:AGENT_CAMPAIGN_ID/report",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__agents__AGENT_CAMPAIGN_ID__report' */'/opt/buildhome/repo/src/pages/campaigns/agents/[AGENT_CAMPAIGN_ID]/report'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/agents/:AGENT_CAMPAIGN_ID/start",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__agents__AGENT_CAMPAIGN_ID__start' */'/opt/buildhome/repo/src/pages/campaigns/agents/[AGENT_CAMPAIGN_ID]/start'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/calls/:CAMPAIGN_CALL_ID/detail",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__calls__CAMPAIGN_CALL_ID__detail' */'/opt/buildhome/repo/src/pages/campaigns/calls/[CAMPAIGN_CALL_ID]/detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/calls/:CAMPAIGN_CALL_ID/report",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__calls__CAMPAIGN_CALL_ID__report' */'/opt/buildhome/repo/src/pages/campaigns/calls/[CAMPAIGN_CALL_ID]/report'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/calls/:CAMPAIGN_CALL_ID/report-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__calls__CAMPAIGN_CALL_ID__report-detail' */'/opt/buildhome/repo/src/pages/campaigns/calls/[CAMPAIGN_CALL_ID]/report-detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/messages/:SMS_CAMPAIGN_ID/report",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__messages__SMS_CAMPAIGN_ID__report' */'/opt/buildhome/repo/src/pages/campaigns/messages/[SMS_CAMPAIGN_ID]/report'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/campaigns/messages/:SMS_CAMPAIGN_ID/detail",
                "icon": "phone",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__campaigns__messages__SMS_CAMPAIGN_ID__detail' */'/opt/buildhome/repo/src/pages/campaigns/messages/[SMS_CAMPAIGN_ID]/detail'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/buildhome/repo/src/pages/404'), loading: LoadingComponent}),
                "layout": false,
                "exact": true
              }
            ]
          },
          {
            "path": "/company/:companyID/wallet",
            "name": "my_wallet",
            "icon": "wallet",
            "routes": [
              {
                "path": "/company/:companyID/wallet/deposit",
                "name": "topup",
                "icon": "file",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallet__deposit' */'/opt/buildhome/repo/src/pages/wallet/deposit'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/wallet/deposit/:PRODUCT_SLUG",
                "icon": "file",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallet__deposit__PRODUCT_SLUG__product' */'/opt/buildhome/repo/src/pages/wallet/deposit/[PRODUCT_SLUG]/product'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/wallet/expense-report",
                "name": "expensereport",
                "icon": "wallet",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallet__order-history-new' */'/opt/buildhome/repo/src/pages/wallet/order-history-new'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/wallet/order-history",
                "name": "orderhistorynew",
                "icon": "wallet",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallet__order-history-new2' */'/opt/buildhome/repo/src/pages/wallet/order-history-new2'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/wallet/transaction",
                "icon": "wallet",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallet__transaction' */'/opt/buildhome/repo/src/pages/wallet/transaction'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/wallet/:PRODUCT_ID/blocks-history",
                "icon": "wallet",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallet__PRODUCT_ID__blocks-history' */'/opt/buildhome/repo/src/pages/wallet/[PRODUCT_ID]/blocks-history'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/company/:companyID/notification",
            "name": "notification",
            "icon": "bell",
            "access": "canAdmin",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__notification' */'/opt/buildhome/repo/src/pages/notification'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/company/:companyID/caller",
            "name": "history",
            "icon": "phone",
            "access": "canAdmin",
            "routes": [
              {
                "path": "/company/:companyID/caller/caller/history",
                "name": "calls_history",
                "icon": "wallet",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__caller__history' */'/opt/buildhome/repo/src/pages/caller/history'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/company/:companyID/settings_new",
            "name": "new_settings",
            "icon": "setting",
            "access": "canAdmin",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__new' */'/opt/buildhome/repo/src/pages/settings/new'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/company/:companyID/help",
            "name": "help",
            "icon": "questionCircle",
            "access": "displayHelp",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__uhelp' */'/opt/buildhome/repo/src/pages/uhelp'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/company/:companyID/affiliate",
            "name": "affiliate",
            "icon": "gift",
            "access": "displayHelp",
            "routes": [
              {
                "path": "/company/:companyID/affiliate/invite",
                "name": "invite",
                "icon": "star",
                "access": "displayHelp",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invite' */'/opt/buildhome/repo/src/pages/invite'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/company/:companyID/affiliate/customers",
                "name": "customers",
                "access": "displayHelp",
                "icon": "star",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__affiliate__customers' */'/opt/buildhome/repo/src/pages/affiliate/customers'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/refresh",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__refresh' */'/opt/buildhome/repo/src/pages/refresh'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/buildhome/repo/src/pages/404'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
